<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Panels List</h4>
          <div class="d-flex justify-content-start">

            <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['en/panels/create'])"
                        :to="'/panels/create/'+lang"
                        class="btn btn-info btn-wd">
              Add New
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>

        </div>

        <!--    filters    -->
        <div class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              placeholder="Select Group"
              :input-classes="'select-default'"
              :list="filterBuilders.groups"
              :listItemLabel="'name'"
              :listItemValue="'id'"
              v-model="filters.group_id">
            </fg-select>
          </div>

          <div class="col-md-2 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">Filter Result</l-button>
          </div>
        </div>
        <!--  end  filters    -->

        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :row-key="'id'"
            :lang="lang"
            :key="componentKey"
            @reorder="reorder($event)"
            :api-url="'panels/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['en/panels/change-active-status']) ||
                                $store.getters['auth/checkAccessOwner']('restricted_own/en/panels/change-active-status', props.row.creator_id)"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['en/panels/update']) ||
                                     $store.getters['auth/checkAccessOwner']('restricted_own/en/panels/update', scope.row.creator_id)"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/panels/edit/'+scope.row.id+'/'+lang">
                  <i class="fa fa-edit"></i></router-link>

                <a v-if="$store.getters['auth/haveOneOfPermissions'](['en/panels/delete']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/en/panels/delete', scope.row.creator_id)"
                   v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>

          </general-data-table>
        </div>

      </card>


      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this panel?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import {Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';


export default {
  components: {
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    FgSelect,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
        {label: 'Group', value: 'group.name', minWidth: '200', align: 'center'}
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      filterBuilders: {
        groups: []
      },

      filters: {
        group_id: null
      },

      deleteModalVisibility: false,
      toDeleteId: undefined,
      lang: 'en',
      componentKey: 0,
    }

  },
    created(){
        const path = window.location.href;
        this.lang = path.substring(path.lastIndexOf('/') + 1)
    },
  async mounted() {
    let data = {
        'lang': this.lang
    }
    let response = await this.axios.post("panels/builder", data);
    this.filterBuilders.groups = response.data.groups;
  },

  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
        'lang': this.lang,
      }
      this.axios.post("panels/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Panel updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
          'lang': this.lang,
      }
      this.axios.delete("panels/delete", {headers: {}, data}).then((response) => {
        this.$notify({
          message: "Panel deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },

    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.group_id) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },

    async reorder(data) {
      try {
        let orderData = [];

        data.forEach((row, i) => {
          orderData.push({"id": row.id, "sort_order": i + 1})
        });
        let body = {
          'data':orderData,
          'lang':this.lang
        }
        await this.axios.post("panels/re-order", body);

        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },

    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },

  },

}
</script>
